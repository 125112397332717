<template>
	<div>
		<lesson-view
			:lesson="lesson"
			@reloadLesson="setLesson"
		/>
	</div>
</template>

<script>

	import LessonView   from '@/components/lessons/LessonView';
	import routeParams  from '@/mixins/routeParams';
	import api          from '@/services/api';

	export default {
		metaInfo () {
			if (!this.getLessonTitle) {
				return false;
			}
			return {
				title: this.getLessonTitle
			};
		},
		layout: 'v2default',
		components: {
			LessonView
		},
		mixins: [routeParams],
		data: () => ({
			lesson: {}
		}),
		computed: {
			getLessonTitle () {
				return this.lesson?.title;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/my-courses',
						text: 'My learning'
					},
					{
						path: `/my-courses/${this.getCourseId}`,
						text: this.lesson.courseTitle
					},
					{
						path: this.getLessonRoute,
						text: this.getLessonTitle
					}
				];
			}
		},
		created: function () {
			this.setLesson();
		},
		methods: {
			async setLesson () {
				const lesson = await api.courses.getLessonById({
					lessonId: this.getLessonId
				});
				if (!lesson) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.lesson = lesson;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-route] {}
</style>
